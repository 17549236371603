@mixin transition($duration: .2s) {
  animation-duration: $duration;
  @include transition-basics($duration);
}

@mixin card()
{
  @include transition();
  background: white;
  border: 1px solid rgba($light-gray, .5);
  box-shadow: 2px 2px 4px rgba($dark-gray, .5);
}

@mixin transparent-layover-text() {
  background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%);
  background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
  background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 );

  color: white;
  text-shadow: 0 0 4px rgba(0,0,0,.9);
}

@mixin translate($x, $y) {
  transform: translate($x, $y);
}

@mixin align-center-middle() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin footer-button() {
  @include align-center-middle();
  @include transition();
  @include button-style($light-gray, $medium-gray, $black);
  cursor: pointer;
  position: relative;
  z-index: $header-zindex - 15;

  height: rem-calc(90);
  width: rem-calc(90);
  max-width: 100%;
  padding: .75em;

  overflow: hidden;

  footer {
    font-size: .75em;
    padding: .2em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255,255,255,.5);

    &:empty {
      display: none;
    }
  }
}