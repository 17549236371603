$canvas-zindex: 3;

$accordion-gap: 3px;

@mixin inactive()
{
  opacity: 0;
  filter: grayscale(.6);
}
.has-tip {
  cursor: pointer;
}
.configurator {
  a {
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
  }

  &.accordion {
    border: 0;
    .accordion-item {
      overflow: hidden;
      margin-bottom: $accordion-gap;
      .accordion-title {
        user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -webkit-user-select: none;
        border: 0;
        @include transition();
        padding: .75rem;
        font-size: 1rem;
        color: $white;
        font-weight: bold;
        background: $medium-gray;
        &:hover {
          background: $dark-gray;
        }
      }
      .accordion-title i.fa {
        @include transition();
        transform: scale(1.5,1.5);
      }
      &.is-active {
        .accordion-title {
          background: $primary-color;
          font-weight:bold;

          i.fa {
            transform: rotate(-180deg) scale(1.5,1.5);
          }
        }
      }
      &.disabled {
        .accordion-title {
          background: $light-gray;
          color: $dark-gray;
          cursor: not-allowed;

          &:hover {
            background: $light-gray;
          }

          i.fa {
            transform: scale(0,0);
          }
        }
        .accordion-content {
          padding: 0 !important;
          max-height: 0 !important;
          overflow: hidden !important;
        }
      }
    }
  }

  .accordion-content {
    padding-top: 0;
    
    max-height: 360px;
    overflow-x: hidden;
    overflow-y: scroll;
    background: $light-gray;
    border: 0;

    h6 {
      display: block;
      margin: .5em 0;
      padding: .3em;
      color: $dark-gray;
      font-weight: bold;
    }

    h6.same-as-door {
      display: none;
    }
  }

  .accordion-content {
    overflow-y: scroll !important;
  }

  .configurator-option {
    @include align-center-middle();
    cursor: pointer;
    margin: .2em auto .8em auto;

    @include transition();
    background: rgba(white, .5);
    border: 2px solid rgba($medium-gray, .6);

    @include breakpoint(large) {
      max-width: rem-calc(80);
    }
    box-sizing: content-box;

    &:hover {
      background: white;
      border-color: rgba($dark-gray, .9);
    }
    border-radius: 2px;
    padding: 0;

    &.selected {
      border-color: $primary-color;
      background: white;
    }

    &.loading {
      opacity: 0.6;

      &:hover {
        cursor: wait;
        border-color: rgba($alert-color, 1);
        position: relative;

        &::after {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          text-align: center;
          font-size: .7em;
          padding: .2em;
          content: 'Lädt...';
          color: $black;
        }
      }
    }
  }

  .configurator-preview {
    display: block;
    position: relative;
    overflow: hidden;
    height: 100%;

    #progressbar {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;

      > svg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 3px !important;
      }
    }

    .static-canvas-wrapper {
      display: block;
      width: 100%;
      min-height: rem-calc(800);
      position: relative;

      .moveable-canvas-wrapper {
        @include transition();
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);

        &[data-hand] {
          cursor: move !important; /* fallback if grab cursor is unsupported */
          cursor: grab !important;
          cursor: -moz-grab !important;
          cursor: -webkit-grab !important;

          &:active {
            cursor: move !important;
            cursor: grabbing !important;
            cursor: -moz-grabbing !important;
            cursor: -webkit-grabbing !important;
          }

          canvas {
            outline: 2px dashed $primary-color;
          }
        }

        .configurator-canvas {
          @include transition();
          position: absolute;
          left: 50%;
          top: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%) scale(.5);
          -moz-transform: translateX(-50%) translateY(-50%) scale(.5);
          -ms-transform: translateX(-50%) translateY(-50%) scale(.5);
          -o-transform: translateX(-50%) translateY(-50%) scale(.5);
          transform: translateX(-50%) translateY(-50%) scale(.5);
          z-index: $canvas-zindex;

          canvas {
            -webkit-transition: transform 0.2s ease;
            -moz-transition: transform 0.2s ease;
            -ms-transition: transform 0.2s ease;
            -o-transition: transform 0.2s ease;
            transition: transform 0.2s ease;
          }
        }

        &[data-camera="overview"] .configurator-canvas {
          @include transition();
          -webkit-transform: translateX(-50%) translateY(-50%) scale(.3);
          -moz-transform: translateX(-50%) translateY(-50%) scale(.3);
          -ms-transform: translateX(-50%) translateY(-50%) scale(.3);
          -o-transform: translateX(-50%) translateY(-50%) scale(.3);
          transform: translateX(-50%) translateY(-50%) scale(.3);
        }
      }
    }

    .preview-layer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      @include align-center-middle();
      display: none;

      &:first-child {
        position: relative;
      }

      > img {
        max-height: 600px;
        max-width: 100%;
      }
    }
  }


  &.push-up {
    @include transition();
    z-index: $header-zindex - 10;
    background: $white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    [data-toggle^="mobile-horizontal-menu"] {
      cursor: pointer;
      color: $dark-gray;
      border-top: 2px solid $dark-gray;
      box-shadow: 0 -2px 3px rgba($medium-gray, .6);
      padding: .6em;

      i.fa {
        @include transition();
      }

      a.randomize-item-definitions {
        margin-left: 1em;
      }
    }

    &.minimized {
      @include translate(0, rem-calc(110));
    }

    &.minimized [data-toggle^="mobile-horizontal-menu"] i.fa.fa-chevron-down {
      transform: rotate(-180deg);
    }

    .menu {
      overflow-x: scroll;

      li {
        height: auto;
        padding: rem-calc(3);

        header {
          color: $dark-gray;
          font-size: .65em;
        }

        div.menu-content {
          @include transition();
          @include mui-fade();
          position: absolute;
          display: block;
          padding-bottom: rem-calc(116);
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0,0,0,.8);
          z-index: $header-zindex - 10;
          color: $white;

          .layeritemdefinition-list {
            max-height: 60vh;
            overflow-y: scroll;

            h6 {
              font-weight: bold;
              font-size: 1.15em;
              margin: .5em;
            }

            .configurator-option {
              display: block;
              margin-bottom: .5em;
              border-radius: 3px;

              &.selected,
              &:hover {
                color: $dark-gray;
              }

              img {
                margin: 0 1em;
              }
              span {
                font-weight: normal;
              }
            }
          }
        }

        div.menu-content:not(.visible) {
          @include transition();
          @include mui-fade(out);
          display: none;
        }

        div.menu-content.visible + div.menu-button {
          @include button-style($primary-color);
          z-index: $header-zindex - 9;

          footer {
            color: $black;
          }
        }
      }
    }
  }

  div.menu-button {
    @include footer-button();
  }
}

.configurator .configurator-information .page-title {
  h3 {
    &:first-child {
      margin-bottom: 0;
    }
  }
}

.configurator table {
  margin: 1.4em 0;
  border: 1px solid $light-gray;

  td {
    font-size: .85em;
  }
}

.panel-heading {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  background: $black;
  margin-top: $accordion-gap * 4;
  .navigator & {
    margin-top: 0;
  }
  margin-bottom: $accordion-gap;
  color: $white;
  font-weight: bold;
  border: 0;
  font-size: 1rem;
  cursor: default;
  &:hover {
    background: $black !important;
  }
}

#navigator {
  h6 {
    display: block;
    margin: 0 0 .5em;
    color: $dark-gray;
    font-weight: bold;
  }
}
#mini-door {
  margin: 0 auto 1rem auto;

  $minidoor-width: 160px;
  $minidoor-height: 150px;
  $toplight-height: 35px;
  $sidepanel-width: 40px;

  width: rem-calc($minidoor-width);
  height: rem-calc($minidoor-height);
  position: relative;

  .button {
    box-sizing: border-box;
    position: absolute;
    border-width: 7px;
    padding: 0;
    margin: 0;
    background: #eee;

    &.primary {
      background: rgba($primary-color, .2);
    }

    -webkit-transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -ms-transition: 0.2s ease all;
    -o-transition: 0.2s ease all;
    transition: 0.2s ease all;

    &:hover {
      -webkit-transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -ms-transition: 0.2s ease all;
      -o-transition: 0.2s ease all;
      transition: 0.2s ease all;
    }

    i.fa.fa-check {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -.35em;
      margin-left: -.5em;
    }

    &.unfilled {
      border-width: 3px;
      border-style: dashed;
      &:not(.primary)
      {
        opacity: .6;
      }

      i.fa.fa-check {
        display: none;
      }
    }

  }

  [data-panel="top"] {
    top: 0;
    left: 0;
    right: 0;
    height: ($toplight-height - 1);
  }

  [data-panel="left"] {
    top: $toplight-height;
    left: 0;
    width: ($sidepanel-width - 1);
    bottom: 0;
  }

  [data-panel="right"] {
    top: $toplight-height;
    right: 0;
    width: ($sidepanel-width - 1);
    bottom: 0;
  }

  [data-panel="center"] {
    top: $toplight-height;
    left: $sidepanel-width;
    right: $sidepanel-width;
    bottom: 0;
  }
}

td[data-panel] i.fa {

  &.fa-times-circle {
    color: $medium-gray;
  }

  &.fa-check-circle {
    color: $primary-color;
  }
}

.configurator-options {
  margin-bottom: 1rem;

  h6 {
    display: block;
    margin: .25rem 0 .5em 0;
    color: $dark-gray;
    font-weight: bold;
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
  }
}