@include foundation-everything(true);

@import "open-sans.scss";

$header-zindex: 100;
$header-height: rem-calc(48);
$content-padding-top: .5rem;

@import "_mixins";

@include breakpoint(large) {
  .medium-shrink {
    @include flex-grid-column(shrink);
  }
}
@include breakpoint(xlarge) {
  .large-shrink {
    @include flex-grid-column(shrink);
  }
}

html {
  height: 100%;
}

.no-padding {
  padding: 0 !important;
}

#search-wrapper {
  position: relative;
}

#search {
  $background-color: $light-gray;
  $border-color: $dark-gray;

  min-width: rem-calc(400);
  width: auto;
  height: auto;
  background: $background-color;
  z-index: $header-zindex+1;
  position: absolute;
  top: rem-calc(20);
  left: rem-calc(-22);
  border: 1px double $border-color;
  box-shadow: 1px 1px 3px rgba($black, .7);
  padding: .5em;

  form {
    position: relative;

    .indicator {
      position: absolute;
      top: 0.5em;
      right: 0.5em;
      text-align: right;
    }

    .loading-indicator {
      color: $primary-color;
    }

    .error-indicator {
      color: $alert-color;
    }

    #cancel-search {
      right: 2.5em;
    }

    #results {
      max-height: rem-calc(500);
      overflow-x: hidden;
    }

    article {
      h5 {
        color: $dark-gray;
        font-weight: bold;
        text-transform: lowercase;
      }

      padding-bottom: .75rem;
      border-bottom: 1px solid $medium-gray;
      margin-bottom: .75rem;

      &:last-child {
        border: 0;
        margin-bottom: 0;
      }

      a.search-block {
        @include footer-button();
        display: block;
        height: auto;
        width: auto;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: rem-calc(-10);
    left: rem-calc(20);
    height: rem-calc(20);
    width: rem-calc(20);
    background: $background-color;
    transform: rotate(-135deg);
    border-bottom:inherit;
    border-right:inherit;
    box-shadow:none;
  }

  @include breakpoint(medium down)
  {
    min-width: 0;
    width: 100%;
    position: relative;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    box-shadow: none;

    &::before {
      display: none;
    }
  }

}

body {
  min-height: 100vh;
  overflow-y: scroll;
}

#wrapper {
  position: relative;
  padding-top: $header-height * 2;
  min-height: 100vh;

  @include breakpoint(medium down) {
    padding-top: $header-height;
    padding-bottom: rem-calc(70);
  }
}

.logo {
  display: inline-block;
  width: rem-calc(106);
  height: rem-calc(36);
  background: url('/img/logo.png') no-repeat center center;
  @include breakpoint(retina) {
    background-image: url('/img/logo@2x.png');
  }
  background-size: 100% 100%;
}

#footer {
  padding: 1em 0;
  background: $medium-gray;
  font-size: rem-calc(11);
  color: $black;

  .column {
    border-left: 1px solid $white;
    &:first-child {
      border: 0;
    }
  }

  section {
    margin-bottom: 1.25em;
  }

  ul {
    list-style-type: none;
    margin: 0;
  }

  span.copyright {
    font-size: rem-calc(11);
    margin: .75em 0;
    display: block;
  }

  span.disclaimer {
    font-size: rem-calc(8);
  }
}

#header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  z-index: $header-zindex;

  #responsive-toggle-button {
    color: #555;
    padding: .5em;
  }

  .title-bar,
  .top-bar,
  .subnavigation-bar {
    position: relative;
    z-index: $header-zindex;

    &.subnavigation-bar {
      z-index: auto;
    }
  }
  #menu {
    position: relative;
    z-index: ($header-zindex - 1);
    @include transition();

    .js-drilldown-back a::before {
      display: none;
    }

    @include breakpoint(small down)
    {
      &, & ul.menu, & a:not(.button) {
        background: white;
      }

      li > a:not(.button) {
        padding: 1em .5em;

        i.fa {
          width: 36px;
          text-align: center;
        }
      }
      .top-bar-right {
        margin: 1em .5em .5em;
      }
    }
  }

  nav {
    a:not(.button) {
      color: $white;
      @include transition();

      &:hover {
        color: $medium-gray;
      }

      &.active {
        color: $primary-color;

        &:hover {
          color: darken($primary-color, 8%);
        }
      }
    }
  }


  /**
   * prevent bug where previous menu points would be displayed underneath the drilldown menu layer
   */
  ul.is-drilldown-submenu.is-active {
    height: 100%;
  }

  li.is-dropdown-submenu-item {
    @include transition();
  }

  li.is-dropdown-submenu-item:hover {
    background: darken($topbar-submenu-background, 5%);
  }
}

#content > .row {
  padding-top: $content-padding-top;
}

#toast-container {
  top: $content-padding-top;
}

[data-toast] {
  display: none;
}

.top-bar {
  padding: 0;
  height: 100%;
}

.image-thumbnails.row {
  margin: 0;

  @include breakpoint(medium down)
  {
    @include flex-grid-layout($n: 3);
  }
  @include breakpoint(large)
  {
    @include flex-grid-layout($n: 4);
  }
  @include breakpoint(xlarge)
  {
    @include flex-grid-layout($n: 5);
  }
  @include breakpoint(xxlarge)
  {
    @include flex-grid-layout($n: 6);
  }

  > .column,
  > .columns {
    margin: 0;
    padding: 0 .1em;
    text-align: center;

    @include breakpoint(medium down) {
      &:nth-child(n+4) {
        display: none;
      }
    }

    @include breakpoint(large down) {
      &:nth-child(n+5) {
        display: none;
      }
    }

    @include breakpoint(xlarge down) {
      &:nth-child(n+6) {
        display: none;
      }
    }

    @include breakpoint(xxlarge down) {
      &:nth-child(n+7) {
        display: none;
      }
    }
  }
}

.no-js {
  @include breakpoint(small only) {
    .top-bar {
      display: none;
    }
  }

  @include breakpoint($topbar-unstack-breakpoint) {
    .title-bar {
      display: none;
    }
  }
}

.item {
  margin-bottom: 1rem;

  &:not(.slick-track &) {
    padding-right: 3rem;
    margin: 2em 0 6em;
  }
  display: block;

  article {
    @include transition();
    color: $black;

    header {
      margin: .5em;
      h3 {
        small {
          color: inherit;
          font-size: inherit;
          font-weight: normal;
          text-transform: lowercase;
        }

        font-weight: bold;
      }
    }

    hr {
      @include transition();
      border: 0;
      border-top: 2px solid $light-gray;
      margin: 0;
    }
  }

  &:hover {
    article {
      background-color: $light-gray;
    }
    hr {
      border-top-color: $medium-gray;
    }
    .button {
      background-color: darken($primary-color, 8%);
    }
  }
}

.door-item
{
  a {
    color: inherit;
    text-align: center;

    &:hover {
      color: inherit;
    }

    > article {
      img {
        margin: 0 auto;
        max-height: 400px;
      }

      > header {
        text-align: center;
        text-transform: lowercase;
        padding: 0 1em;

        > small {
          color: $medium-gray;
        }
      }
      > footer {
        padding: .7em;
      }
    }
  }


  @include breakpoint(xlarge)
  {
    margin-bottom: 3em;
  }
}

img {
  user-select: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.subnavigation-bar {
  background: $medium-gray;
  min-height: $header-height;
  line-height: $header-height;

  &.back-button-only {
    background: transparent;
  }

  a {
    color: $black;

    &.active {
      font-weight: bold;
    }
  }
}

section.gray {
  background: $light-gray;
}

.page-title {
  margin-top: .2em;
  @include breakpoint($topbar-unstack-breakpoint) {
    margin-top: 1em;
  }
  text-transform: lowercase;

  h1 {
    text-align: center;
    @include breakpoint(medium down)
    {
      font-size: 1.1rem;
    }
    @include breakpoint($topbar-unstack-breakpoint)
    {
      text-align: left;
      margin-left: - map_get($grid-column-gutter, small);
    }
  }

  h2 {
    display: none;
    @include breakpoint($topbar-unstack-breakpoint)
    {
      font-weight: lighter;
      display: block;
    }
  }

  .bold {
    font-weight:bold;
  }
  .green {
    color: $primary-color;
  }
}


.pdf-page-title {
  margin-top: .2em;
  text-transform: lowercase;

  > img {
    max-height: 60px;
    margin-bottom: 25px;
  }

  h1 {
    text-align: center;
    font-size: 1.2rem;
  }

  h2 {
    font-weight: lighter;
    display: block;
  }

  .bold {
    font-weight:bold;
  }
  .green {
    color: $primary-color;
  }
}

.whitespace {
  height: 3rem;
}

a.level-up-button {
  font-size: .75em;
  color: $dark-gray;

  .back-button-only & {
    background: rgba($white, .8);
    padding: .5em;
  }

  i.fa {
    margin-right: .5em;
  }
}
a.home {
  $home-button-size: rem-calc(20);
  $home-button-gutter-width: rem-calc(2);

  position: relative;
  @include button($background: $white, $background-hover: $light-gray);

  &.active {
    @include button();
  }

  padding: 0;
  border: 0;
  width: $home-button-size;
  height: $home-button-size;


  > .home-silhouette,
  &::after {
    content: ' ';
    display: block;
    position: absolute;
    background: $topbar-background;
  }

  > .home-silhouette {
    top: ($home-button-size - $home-button-gutter-width)/2;
    left: 0;
    right: 0;
    height: $home-button-gutter-width;
  }

  &::after {
    top: 0;
    left: ($home-button-size - $home-button-gutter-width)/2;
    width: $home-button-gutter-width;
    bottom: 0;
  }
}

.no-margin {
  margin: 0 !important;
}