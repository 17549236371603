#mobile-menu {
  position: relative;
  z-index: $header-zindex - 1;
  transition: 0.15s;
  padding-bottom: 1em;

  &,
  .is-drilldown-submenu {
    background: $dark-gray;
  }

  li > a {
    display: block;
    color: $white;
    font-weight: bold;
    font-size: 1.1em;
    text-shadow: 0 0 1px $black;
  }

  .is-drilldown-submenu-parent a::after {
    display: none;
  }

  .is-drilldown {
    width: 100%;
    max-width: 100% !important;
  }

  .js-drilldown-back > a {
    &::before {
      display: none;
    }
    margin-bottom: .5em;
    background: darken($dark-gray, 5%);
  }
}

.draft {
  position: relative;
  opacity: .4;

  .draft & {
    opacity: 1;
  }

  &::before {
    position: absolute;
    color: #444;
    top: .1rem;
    right: 1rem;
    font-size: 1.5rem;
    font-family: 'FontAwesome';
    content: '\f070';
  }
}