.dot-navigation {
  $dot-size: .8em;
  $dot-padding: 1rem 1.25rem;
  @include transition(.1s);
  background: transparent;
  text-align: center;

  ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
  }


  li {
    position: relative;
    display: inline-block;
  }

  &.fixed-right {
    position: fixed;
    right: 0;
    top: $header-height;
    bottom: 0;
    text-align: right;
    z-index: ($header-zindex - 2);
    overflow: visible;
    width: 0;

    @include breakpoint(large)
    {
      @include align-center-middle();
    }

    ul {
      position: absolute;
      right: 0;
      bottom: 2rem;
      @include transition(.1s);
      font-size: 1.2em;

      @include breakpoint(large)
      {
        right: 2rem;
        position: relative;
        bottom: auto;
      }
    }

    &.touching {
      @include breakpoint(medium down)
      {
        @include transition(.3s);
        background: rgba($light-gray,.8);

        width: rem-calc(60);

        ul {
          @include transition(.1s);
        }
      }
    }

    li {
      display: block;
    }

    a.to-top {
      padding: $dot-padding;
      display: block;

      > span {
        display: block;
        height: $dot-size;
        width: $dot-size;
        line-height: $dot-size;
        text-align: center;
        vertical-align: middle;

        i.fa {
          font-size: 1.2rem;
          color: lighten($secondary-color,20%);
          text-shadow: 0px 0px 4px rgba($white,.66), 0px 0px 1px rgba($white,.75);
          @include transition();
        }
      }

      &.touching > span,
      body.no-touch &:hover > span {
        i.fa {
          color: white;
          text-shadow: 0px 0px 14px rgba($primary-color,.95), 0px 0px 1px rgba($primary-color,.95);
        }
      }
    }

  }


  a.preview {
    display: none;
    position: absolute;
    z-index: ($header-zindex + 1);
    height: ($dot-size * 10);
    max-height: ($dot-size * 10);
    width: rem-calc(200);
    max-width: rem-calc(200);
    left: -(rem-calc(210));
    top: -($dot-size * 2.5);

    &.to-top-preview {
      height: auto;
      line-height: rem-calc(20);
      top: 0;
    }

    .small.callout.preview {
      padding: .3em 1em;
      box-shadow: 4px 4px 6px rgba($dark-gray,.75);
    }

    header {
      text-transform: uppercase;
      font-size: .8em;
      margin-bottom: .6em;
      text-align: center;
    }

    &.to-top-preview header {
      text-transform: none;
      font-size: .8em;
      margin-bottom: 0;
    }
  }

  &.touching a.touching ~ a.preview,
  body.no-touch & a:hover ~ a.preview {
    @include transition(.5s);
    @include mui-animation(slide(in, left, 10%), fade(in));
    display: block;
  }

  .dot {
    padding: $dot-padding;
    display: block;

    &::after {
      content: '';
      display: block;
      box-shadow: 0px 0px 4px rgba($white,.66);
      background: rgba($secondary-color, .5);
      border-radius: ($dot-size * 2);
      @include transition();
      height: $dot-size;
      width: $dot-size;
    }
    &.active::after {
      background: rgba($primary-color, .75);
    }

    &.touching,
    body.no-touch &:hover {
      &::after {
        background: rgba(white, .5);
        box-shadow: 0px 0px 14px rgba($primary-color,.95);
      }
      &.active::after {
        background: rgba($primary-color, .9);
      }
    }
  }

}