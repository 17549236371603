
[data-horizontal-slider],
[data-horizontal-slider-nav] {
  #content > & {
    padding-top: 0;
    margin-top: -.5em;
  }
  text-align: center;

  .slick-slider, .slick-list, .slick-track {
    height: 100%;
  }

  .slick-slide {
    @include transition();
    cursor: pointer;
  }
  > div {
    @include transition();
  }

  @include breakpoint(medium down)
  {
    &:not(.slick-initialized) {
      background: url(/img/ajax-loader.gif) no-repeat center center;
      position: relative;

      > div {
        opacity: 0;
        position: absolute;
      }

      .slick-list {
        opacity: 0;
        position: absolute;
      }
    }
  }
}

[data-horizontal-slider] {

  .slick-slide {
    transform: scale(1);
    img {
      max-height: rem-calc(250);
    }
  }

  .slick-slide:not(.slick-current) {
    transform: scale(.875);
    opacity: 0.75;

    button.button {
      display: none;
    }
  }
}

[data-horizontal-slider-nav] {

  background: rgba($white, .65);

  &:not(.slick-initialized) {
    background: none;
  }


  .slick-list {
    max-width: 90%;
    max-height: rem-calc(130);
    margin: rem-calc(12) auto;
  }

  * {
    outline: 0;
  }

  .slick-slide {
    border-bottom-width: 5px;
    border-bottom-style: solid;
    padding: 0 .1em;

    img {
      max-height: rem-calc(52);
      min-width: rem-calc(20);
      margin: 0 auto;
    }

    &.slick-current {
      border-bottom-color: $primary-color;
    }
  }

  .slick-slide:not(.slick-current) {
    opacity: 0.5;
    border-bottom-color: $medium-gray;
  }

  @include breakpoint(large)
  {
    display: none;
  }
}