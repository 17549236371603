@import "_headimports";
@import "_global";
@import "user";
@import "configurator";
@import "dot-navigation";
@import "horizontal-slider";

.tiles {
  @include breakpoint(medium)
  {
    margin: (- map_get($grid-column-gutter, medium)/2) 0;
  }

  article {
    border: 1px solid $dark-gray;
    margin: (map_get($grid-column-gutter, medium)/2) 0;
  }
}

.edition-item {
  background-size: cover;
  padding: 0;

  article {
    position: relative;
    overflow: hidden;

    background: -moz-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.75) 100%) !important;
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.75) 100%) !important;
    background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%, rgba(0,0,0,0.75) 100%) !important;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#b0000000',GradientType=0 ) !important;

    header {
      z-index: 2;
      min-height: rem-calc(220);
      color: $white;
      text-shadow: 1px 1px 6px rgba($black, .75);
      position: relative;
      h3 {
        z-index: 2;
        position: absolute;
        bottom: -1.5rem;
        padding: .4em 1em 0;
        margin: 0;
        font-size: 3rem;
      }
    }
    footer {
      z-index: 2;
      position: relative;
    }
    hr {
      display: none;
    }
    button.button {
      z-index: 2;
      box-shadow: 0px 0px 17px 10px rgba($black, .75);
    }
  }
}

#kachel1,
#kachel2,
#kachel3,
#kachel4,
#kachel5 {
  width: 100%;
  min-height: 260px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

#kachel1 {
  background-image: url('/img/kachel1.jpg');
}
#kachel2 {
  background-image: url('/img/kachel2.jpg');
}
#kachel3 {
  background-image: url('/img/kachel3.jpg');
}
#kachel4 {
  background-image: url('/img/kachel4.jpg');
}
#kachel5 {
  background-image: url('/img/kachel5.jpg');
}